<template lang="pug">
  .plan-status(
    :class="variant"
    :title="$t(`plans_exports.desc.${exportStatus}`)"
    v-b-tooltip.hover="{ container: '#body', placement: 'top', customClass: 'plan-status-tooltip', variant }"
  )
    .icon
      FaIcon(
        v-if="exportStatus === 'not_set'"
        icon="clock"
      )
      FaIcon(
        v-else-if="exportStatus === 'applied'"
        icon="check-circle"
      )
      FaIcon(
        v-else
        icon="exclamation-circle"
      )
    .status {{ $t(`plans_exports.${exportStatus}`) }}
</template>

<script>
  export default {
    props: {
      exportStatus: {
        type: String,
        default: "not_set"
      }
    },

    computed: {
      variant() {
        switch (this.exportStatus) {
          case "applied":
            return "success"
          case "create_failed":
          case "update_failed":
          case "delete_failed":
            return "danger"
          default:
            return "default"
        }
      }
    }
  }
</script>

<style lang="sass">
  @import "@/assets/styles/variables.sass"

  .plan-status-tooltip
    .tooltip-inner
      padding: 7px
      max-width: 300px
      background: white !important
      color: $default-black !important

    &.b-tooltip-danger .tooltip-inner
      border: 1px solid transparentize($default-red, 0.25)

    &.b-tooltip-default .tooltip-inner
      border: 1px solid transparentize($default-blue, 0.25)

    &.b-tooltip-success .tooltip-inner
      border: 1px solid transparentize($default-green, 0.25)
</style>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"

  .plan-status
    font-size: 0.8rem
    cursor: help
    width: fit-content
    display: flex
    align-items: center
    white-space: nowrap
    font-weight: 600
    gap: 5px
    padding: 0 8px
    height: 34px
    border-radius: 5px
    transition: all 0.15s linear

    &:hover
      box-shadow: 0 1px 10px -8px $default-black

    &.default
      background: transparentize($default-blue, 0.94)
      border: 1px solid transparentize($default-blue, 0.9)
      color: $default-blue

    &.success
      background: transparentize($default-green, 0.94)
      border: 1px solid transparentize($default-green, 0.9)
      color: $default-green

    &.danger
      background: transparentize($default-red, 0.94)
      border: 1px solid transparentize($default-red, 0.9)
      color: $default-red
</style>
